<template lang="pug">
shoperb-modal(:label="translate('admin.settings.edit.edit_storefront_access' )" size="lg" @close="onCancel")
  template(#default)
    .v3-field
      helpers-label.label(field="settings.password")
      input( name="settings.password" v-model="settings.password" :class="{invalid: isInvalid('settings.password')}")
      helpers-validation-message.error(:errors="validation" field="settings.password")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true, default: () => ({})}
  },
  emits:{
    close: {}
  },
}
</script>
