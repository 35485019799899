<template>
  <shoperb-modal :label="menu.id ? translate('admin.menus.new.edit') : translate('admin.menus.new.title')" size="lg" @close="$emit('close')">
    <template #default>
      <div class="space-y-4">
        <shoperb-form-input :label="translate('activerecord.attributes.menu.name')" type="text" name="menu.name" v-model="menu.name" />
        <helpers-validation-message class="error" :errors="validation" field="menu.name" />

        <shoperb-form-input :label="translate('activerecord.attributes.menu.handle')" type="text" name="menu.name" v-model="menu.handle" @keyup="stopGenHandle"/>
        <helpers-validation-message class="error" :errors="validation" field="menu.handle" />
      </div>
    </template>
    <template #footer>
      <shoperb-buttons class="w-full" v-if="menu.id">
        <template #secondary>
          <shoperb-button role="destroy" :label="translate('admin.buttons.delete')" outline @click="deleteConfirm" v-if="$can('menus__delete')"/>
          <shoperb-button role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="$emit('close')" />
        </template>

        <shoperb-button role="primary" :label="translate('admin.buttons.save')"  @click="onSubmit" v-if="$can('menus__update')" />

      </shoperb-buttons>

      <shoperb-buttons class="w-full" v-else>
        <template #secondary>
          <shoperb-button role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="$emit('close')" />
        </template>

        <shoperb-button role="primary" :label="translate('admin.menus.new.add_menu')"  @click="onSubmit" v-if="$can('menus__create')" />

      </shoperb-buttons>
    </template>
  </shoperb-modal>
</template>
<script>
import base from '../../mixins/base.js'
import gen_handle from '../../mixins/gen_handle.js';
import ShoperbFormInput
  from "@/admin/components/helpers/v3-form-input.vue";
export default{
  components: {ShoperbFormInput},
  mixins: [base, gen_handle],
  model_klass: "menu", // for handler

  props: {
    onUpdate: {type: Function, required: false},
    menu: {type: Object, required: true},
  },

  emits:{
    cancel: {}
  },

  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.$validate()) { return; }
      if (this.menu.id) {
        this.onUpdate(this.menu);
      } else {
        this.onCreate(e);
      }
      Cache.deleteBy('menus');
    },

    onCreate(e) {
      return this.axios.post("menus", {menu: this.menu || {}}).then(response => {
        this.menu = response.data;
        this.$emit('close')
        this.navigate(`/menus/${this.menu.id}/edit`);
      }).catch(resp => this.set_validation2(this, 'menu', resp));
    },


    deleteConfirm(e){
      e.preventDefault();
      this.$emit('close');
      this.modal_delete = { type: "menu", yes_function: this.onDestroy };
    },

    onDestroy(e) {
      e.preventDefault();
      this.axios.delete(`menus/${this.menu.id}`).then(response => {
        this.closeModal();
        this.navigate("/menus");
      });
    }
  }
}
</script>
<script
  setup
  lang="ts">
</script>
<script
  setup
  lang="ts">
</script>