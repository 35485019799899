<template lang="pug">
shoperb-modal(:label="translate('admin.settings.edit.edit_standards_and_formats' )" size="lg" @close="onCancel")
  template(#default)
    .v3-field.clearfix
      helpers-label.label(field="settings.time_zone")
      select.fancy-select-2( v-model="settings.time_zone")
        option( v-for="option in time_zone_options" v-text="option.text" :value="option.value")
      helpers-validation-message.error(:errors="validation" field="settings.time_zone")

    .v3-field.clearfix
      helpers-label.label(field="settings.currency")
      select.fancy-select-2( v-model="settings.currency_id")
        option( v-for="currency in currencies" v-text="currencyVisual(currency)" :value="currency.id")
      helpers-validation-message.error(:errors="validation" field="settings.currency_id")

    .v3-field.clearfix
      helpers-label.label(field="settings.unit_system")
      select.fancy-select-2( v-model="settings.unit_system")
        option( v-for="option in unit_system_options" v-text="option.text" :value="option.value")
      helpers-validation-message.error(:errors="validation" field="settings.unit_system")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true, default: () => ({})},
    currencies: {type: Array, required: true, default: () => ([])},
  },
  emits:{
    close: {}
  },
  data() {
    return {
      unit_systems: [],
      time_zones: [],
     };
  },
  validations: {
    "settings.time_zone":   "required",
    "settings.unit_system": "required",
    "settings.currency_id": "required"
  },

  computed: {
    time_zone_options() {
      return (this.time_zones || []).map(item => ({
        text: item.name,
        value: item.value
      }));
    },

    unit_system_options() {
      return (this.unit_systems || []).map(item => ({
        text: item.name,
        value: item.value
      }));
    }
  },
  created() {
    this.fetch_cached_collection('time_zones')
    this.fetch_cached_collection('unit_systems')
    this.old_settings = JSON.parse(JSON.stringify(this.settings));
  },

  methods: {
    currencyVisual(currency) {
      let {name} = currency;
      if (currency.symbol) { name += ', ' + currency.symbol; }
      return name;
    }
  }
}
</script>
