<template lang="pug">
shoperb-modal(:label="translate('admin.settings.edit.edit_order_id_format')" size="lg" @close="onCancel")
  template(#default)
    .v3-field
      helpers-label.label(field="settings.sequence_format")
      input( name="settings.sequence_format" v-model="settings.sequence_format" :class="{invalid: isInvalid('settings.sequence_format')}")
      helpers-validation-message.error(:errors="validation" field="settings.sequence_format")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")

</template>
<script>
  import Root from '@/admin/mixins/root_computed.js';
  import settings from './settings.js';
  export default{
    mixins: [Root,settings],
    props:{
      settings: {type: Object, required: true, default: () => ({})}
    },
    emits:{
      close: {}
    }
  }
</script>
