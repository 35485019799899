<template lang="pug">
shoperb-modal(:label="translate('admin.settings.edit.edit_store_information' )" size="lg" @close="onCancel")
    template(#default)
      .v3-field
        helpers-label.label(field="settings.address.first_name")
        input( name="settings.address.first_name" v-model="settings.address.first_name" :class="{invalid: isInvalid('settings.address.first_name')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.first_name")

      .v3-field
        helpers-label.label(field="settings.address.last_name")
        input( name="settings.address.last_name" v-model="settings.address.last_name" :class="{invalid: isInvalid('settings.address.last_name')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.last_name")

    template(#footer)
      shoperb-buttons(class="w-full")
        template(#secondary)
          shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
        shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true}
  },
  emits:{
    close: {}
  },
  validations: {
    "settings.address.first_name":       "required, maxLength: 255",
    "settings.address.last_name":       "required, maxLength: 255"
  }
}
</script>
