<template>
  <div class="flex flex-col">
    <label :for="id" v-if="label" class="font-medium mb-1 text-sm">{{ label }}</label>
    <textarea v-model="value" :id="id" v-bind="$attrs" class="px-2 block w-full rounded border-0 py-1.5 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6" />
  </div>
</template>

<script>
export default {
  name: "shoperb-form-textarea",
  components: {  },
  props: {
    modelValue: {
      type: String,
      required: false, // may be not set
    },
    label: {
      type: String
    },
    id: {
      type: String,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./app/javascript/admin/styles/admin-v3/variables.scss";

textarea {
  //height: $form-element-height;
  //background-color: $form-element-background-color;
  //border: 1px solid $form-element-border-color; // HSL: 215, 25, 75
  //box-shadow: inset 0 0 3px 0 #A3B0C2, inset 0 0 5px 0 #D1D7E0;
  //box-shadow: inset 0 0 3px 0 rgba(163,176,194,0.6), inset 0 0 6px 0 rgba(209,215,224,0.3);
  //box-shadow: inset 0 0 3px 0 rgba(163, 176, 194, 0.3), inset 0 0 6px 0 rgba(209, 215, 224, 0.15);
  //border-radius: $form-element-border-radius;
  //padding: 0 10px;
  //font-size: 15px;

  &:hover {
    //background: #FCFCFD;
    //background: #FFFFFF;
    //border-color: #8BA1C1;
    //background: #FCFCFD;
    background: $form-element-background-color-hover;
    //border-color: #2671D9;
    border-color: $form-element-border-color-hover;
    //box-shadow: inset 0 0 3px 0 #8BA1C1, inset 0 0 6px 0 #BDC9DB;
  }

  &:focus {
    //background: #FCFCFD;
    //background: #FFFFFF;
    //border-color: #2671D9;
    //box-shadow: inset 0 0 3px 0 #679BE4, inset 0 0 6px 0 #A8C6F0;
    //box-shadow: 0 0 0 4px #E8F1FC;
  }

  &[disabled] {
    background-color: #F2F2F2;
    border-color: #D9D9D9;
    box-shadow: none;
  }

  &.success {
    background: #FCFCFD;
    background: #FFFFFF;
    border: 2px solid #29A352;
    box-shadow: inset 0 0 3px 0 #82C99A, inset 0 0 6px 0 #B8E0C5;
  }

  &.error {
    background: #FCFCFD;
    background: #FFFFFF;
    border: 2px solid #D92626;
    box-shadow: inset 0 0 3px 0 #C98282, inset 0 0 6px 0 #E0B8B8;
  }
}

textarea {
  padding: 10px;
  resize: none;
}

.v3-field {
  display: flex;
  flex-direction: column;
  justify-self: stretch;
  margin-bottom: 0;

  label {
    font-weight: 600;
    color: #29313D;
    font-size: 0.875rem;
    padding: 0 5px;
    line-height: 1;
    margin-bottom: 5px;
    white-space: nowrap;
    cursor: pointer;
  }

  .error {
    font-size: 12px;
    color: #FF4D4F;
  }
}
</style>