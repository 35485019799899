<template>
  <shoperb-modal :label="subscription.id ? translate('admin.customer_subscriptions.edit.edit') : translate('admin.customer_subscriptions.edit.new')" size="lg" @close="onCancel">
    <template #default>
      {{subscription}}
      <div class="field">
        <helpers-label class="label" field="subscription.plan"></helpers-label>
        <select-autocomplete v-model="subscription.plan" :collection="plans" />
        <helpers-validation-message :errors="validation" class="error" field="subscription.plan_id"></helpers-validation-message>
      </div>

      <!--  not best one one as we may have a lot of customers and we will. So, should we use pure search? -->
      <div class="field">
        <helpers-label class="label" field="subscription.customer"></helpers-label>
        <select-autocomplete v-model="subscription.customer" :collection="customers" />
        <helpers-validation-message :errors="validation" class="error" field="subscription.customer_id"></helpers-validation-message>
      </div>

      <div class="field">
        <helpers-label class="label" field="subscription.starts_at"></helpers-label>
        <datepicker v-model="subscription.starts_at" format="dd/MM/yyyy" placeholder="--/--/----" />
        <helpers-validation-message :errors="validation" class="error" field="subscription.starts_at"></helpers-validation-message>
      </div>
    </template>

    <template #footer>
      <shoperb-buttons class="w-full">
        <template #secondary>
          <shoperb-button role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel" v-if="!subscription.id"/>
          <shoperb-button role="destroy" :label="translate('admin.buttons.delete')" outline @click="deleteConfirm" v-if="subscription.id"/>
        </template>

        <shoperb-button role="primary" :label="subscription.id? translate('admin.buttons.save') : translate('admin-v3.subscriptions.edit.add_subscription')" @click="onSubmit" />
      </shoperb-buttons>
    </template>
  </shoperb-modal>
</template>
<style lang="scss">
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
</style>
<script>
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
import Root from '@/admin/mixins/root_computed.js';
import datepicker from '@/admin/components/helpers/datepicker.vue'
export default{
  components: {datepicker},
  mixins: [Root],
  props:{
    subscription: {type: Object, required: true}
  },
  emits:{
    close: {}
  },
  data() {
    return {
      plans: [],
      customers: []
    };
  },

  validations: {
    "subscription.plan_id":        "required",
    "subscription.customer_id":    "required",
    "subscription.starts_at":      "required"
  },

  watch: {
    "subscription.plan"(obj, old){
      return this.subscription.plan_id = obj?.id;
    },
    "subscription.customer"(obj, old){
      return this.subscription.customer_id = obj?.id;
    }
  },
  mounted() {
    this.subscription.plan = this.subscription.cached_plan
    this.subscription.customer = this.customers.findById(this.subscription.customer_id)
  },

  created() {
    this.fetch_cached_collection_all("customer_subscription_plans").then(col=> { return this.plans = col; });

    // not best one one as we may have a lot of customers and we will :)
    this.fetch_cached_collection_all("customers", {fields: ['id', 'name', 'first_name', 'last_name']}).then(col=> {
      this.customers = [];
      for (let c of col) {
        c.name = `${c.first_name} ${c.last_name}`;
        this.customers.push(c);
      }
    });
  },
  methods: {
    onSubmit(e){
      let request;
      e.preventDefault();
      if (!this.$validate()) { return; }

      if (this.subscription.id) {
        request = this.axios.patch(`/customer_subscriptions/${this.subscription.id}`, {customer_subscription: this.subscription});
      } else {
        this.subscription.state = 'incomplete'
        request = this.axios.post("/customer_subscriptions", {customer_subscription: this.subscription});
      }

      request.catch(response => {
        this.set_validation2(this,"subscription", response);
        Cache.deleteBy('customer_subscription');
      });
      return request.then(obj => {
        Cache.deleteBy('customer_subscription');
        this.onCancel();
        // redirect to new object
        if (!this.subscription.id) { this.navigate("/customer-subscriptions"); }
      });
    },

    onCancel(){
      this.$emit('close')
    },

    deleteConfirm(e){
      e.preventDefault();
      this.modal_delete = { type: "subscription", yes_function: this.onDestroy };
    },

    onDestroy(e) {
      e.preventDefault();
      this.axios.delete(`customer_subscriptions/${this.subscription.id}`).then(response => {
        Cache.deleteBy('customer_subscription');
        this.closeModal();
        this.navigate('/customer-subscriptions');
      });
    }
  }
}
</script>
