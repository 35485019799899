<template lang="pug">
shoperb-modal(label="Edit Gift Card" size="lg" @close="$emit('close')")
  template(#default)
    .v3-field
      helpers-label.label(field="gift_card.code")
      | {{prettyCode}}

    .v3-field
      helpers-label.label(field="generate.status")
      select( v-model="gift_card.status")
        option( value="pending") Pending
        option( value="sold") Sold
        option( value="used") Used
        option( value="deleted") Deleted
      span( v-if="order.number")
        | (Ref:)&nbsp;
        | {{order.number}}

    .v3-field( v-if="gift_card.amount")
      h3 Value
      div
        | Total:&nbsp;
        | {{gift_card.amount.toFixed(2)}} {{currency(current_shop).code}}
      div
        | Available:&nbsp;
        | {{gift_card.amount_left.toFixed(2)}} {{currency(current_shop).code}}


    .v3-field.clearfix
      .title
        | Expires at
      .date-w-time
        .date( :class="{invalid: isInvalid('gift_card.expires_at')}")
          datepicker( v-model="gift_card.expires_at_date" format="dd/MM/yyyy" placeholder="--/--/----")
          helpers-validation-message.error(:errors="validation" field="gift_card.expires_at")
          helpers-validation-message.error(:errors="validation" field="gift_card.expires_at_date")
          .icon-calendar
        span
          | at
        .time
          .select.fancy-select( @click="openDropDownMenu")
            .fake-input( data="fake-name" v-text="gift_card.expires_at_hour")
            ul.custom-dropdown.time
              li( @click="gift_card.expires_at_hour='00'" :class="{active: gift_card.expires_at_hour=='00'}")
                | 00
              li( v-for="n in 23" @click="gift_card.expires_at_hour=n.pad(2)" :class="{active: gift_card.expires_at_hour==n.pad(2)}")
                | {{n.pad(2)}}
        span
          | :
        .time
          .select.fancy-select( @click="openDropDownMenu")
            .fake-input( data="fake-name" v-text="gift_card.expires_at_minute")
            ul.custom-dropdown.time
              li( v-for="n in [0, 15, 30, 45]" @click="gift_card.expires_at_minute=n.pad(2)" :class="{active: gift_card.expires_at_minute==n.pad(2)}")
                | {{n.pad(2)}}


    .v3-field
      helpers-label.label(field="gift_card.owner_email")
      input( name="gift_card.owner_email" v-model="gift_card.owner_email"  :class="{invalid: isInvalid('gift_card.owner_email')}")
      helpers-validation-message.error(:errors="validation" field="gift_card.owner_email")
      .button.secondary.inverted.short( @click.prevent="sendCode(current_user.email)")
        | Send myself
      .button.secondary.inverted.short( @click.prevent="sendCode()")
        | Resend

    .v3-field
      h3 Usage
      div
        | {{gift_card.usage}}

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="$emit('close')")
    shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import moment from 'moment';
import Root from '@/admin/mixins/root_computed.js';
import datepicker from '@/admin/components/helpers/datepicker.vue'
export default{
  mixins: [Root,datepicker],
  props: {
    gift_card: {type: Object, required: true, default: ()=>{return {code: ""}}},
  },
  emits: {
    close: {},
  },
  data() {
    return {
      product: {},
      order: {}
    };
  },

  validations: {
    "gift_card.expires_at": "required",
    "gift_card.owner_email": "email"
  },

  watch: {
    "gift_card.expires_at_date"() { this.calcNewTime(); },
    "gift_card.expires_at_hour"() { this.calcNewTime(); },
    "gift_card.expires_at_minute"() { this.calcNewTime(); }
  },

  mounted() {
    this.loadProduct();
  },

  computed: {
    prettyCode() {
      const {
        code
      } = this.gift_card;
      let pretty = "";
      for (let i = 0; i < code.length; i++) {
        var char = code[i];
        pretty += char;
        if ((((i+1)%4)===0) && ((i+1) < code.length)) { pretty += "-"; }
      }
      return pretty;
    }
  },
  methods: {
    saveChanges() {
      if (!this.$validate()) { return Promise.reject(); }

      const request = this.axios.patch(`/gift_cards/${this.gift_card.id}`, {gift_card: this.gift_card});

      return request.catch(response => {
        this.set_validation2(this,"gift_card", response);
        Cache.deleteBy('gift_card');
      });
    },
    onSubmit(e){
      e?.preventDefault();

      const request = this.saveChanges();
      request.then(obj => {
        Cache.deleteBy('gift_card');
        this.closeModal();
        this.navigate("/gift-cards");
      });
    },

    onCancel(e){
      e.preventDefault();
      this.closeModal();
    },

    calcNewTime() {
      let {
        expires_at_date
      } = this.gift_card;
      if (typeof expires_at_date === "object") { expires_at_date = expires_at_date.format('{yyyy}-{MM}-{dd}'); }

      const time = `${expires_at_date}T${this.gift_card.expires_at_hour}:${this.gift_card.expires_at_minute}:00`;
      const new_time = moment(time).utc().format();
      this.gift_card.expires_at = new_time;
    },

    sendCode(email){
      const request = this.saveChanges();
      request.then(obj => {
       this.axios.post(`/gift_cards/${this.gift_card.id}/send`, {email}).then(resp=> {
          console.log("resp", resp.data);
        });
      });
    },
    loadProduct() {
      if (!this.gift_card.order_item_id) { return; }

      return this.axios.get(`/order_items/${this.gift_card.order_item_id}`, {fields: "id,product_id,order_id"}).then(resp=> {
        this.axios.get(`/products/${resp.data.product_id}`, {fields: "id,name"}).then(product=> { return this.product = product.data; });
        return this.axios.get(`/orders/${resp.data.order_id}`, {fields: "id,number"}).then(order=> { return this.order = order.data; });
      });
    }
  }
}
</script>
