<template>
  <form @submit="onSubmit" @keydown.esc="onCancel">
    <div class="modal-header">
      <h2>{{translate('admin-v3.procurement.edit.title')}}</h2>
      <div class="icon-delete" @click="closeModal"></div>
    </div>
    <div class="modal-content">
      <div class="field">
        <shoperb-form-textarea v-model="purchase_order.comments" label="Comment" :rows="10" :required="true"/>
        <helpers-validation-message class="error" :errors="validation" field="purchase_order.comments" />
      </div>
    </div>
    <div class="modal-footer">
      <div class="left">
        <div class="button secondary inverted short" @click="closeModal">{{translate('admin-v3.shared.buttons.cancel')}}</div>
      </div>
      <div class="right">
        <button class="button primary short" type="submit" @click="onSubmit">{{translate('admin-v3.procurement.button_create')}}</button>
      </div>
    </div>
  </form>
</template>
<script lang="js">
import Root from '@/admin/mixins/root_computed.js'
import ShoperbFormTextarea
  from "@/admin/components/helpers/v3-form-textarea.vue";
export default {
  components: {ShoperbFormTextarea},
  mixins: [Root],
  model_klass: "purchase_order",

  data() { return {
    purchase_order: { comments: null, translations: {}},
  }},

  methods:{
    onSubmit(e) {
      e.preventDefault();
      if (!this.$validate()) { return; }
      return this.onUpdate(e);
    },

    onUpdate(e) {
      const request = this.api_request("PATCH", `/inventory/purchase-orders/${this.purchase_order.id}/cancel`, {purchase_order: this.purchase_order});
      request.catch(response => {
        this.set_validation(this, "purchase_order", response);
        return Cache.deleteBy('purchase_order');
      });

      return request.then(response => {
        const parse = response.json();
        parse.then(obj => {
          return this.closeModal();
        });

        return this.validation.purchase_order = {};
      });
    },

    onCancel(e) {
      return this.closeModal();
    },

  }
}

</script>