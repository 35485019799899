<template lang="pug">
shoperb-modal(:label="translate('admin.settings.edit.edit_storefront_access' )" size="lg" @close="onCancel")
  template(#default)
    .v3-field
      helpers-label.label(field="settings.private_shop_message")
      textarea( name="settings.private_shop_message" v-model="settings.private_shop_message" :class="{invalid: isInvalid('settings.private_shop_message')}")
      helpers-validation-message.error(:errors="validation" field="settings.private_shop_message")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>
import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true, default: () => ({})}
  },
  emits:{
    close: {}
  },
  validations: {
    "settings.private_shop_message": "required, maxLength: 2000"
  }
}
</script>
