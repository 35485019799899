<template lang="pug">
shoperb-modal(label="SEO" size="lg" @close="onCancel")
  template(#default)
    .v3-field
      label.label( for="settings_meta_title")
        | {{ translate('activerecord.attributes.product.meta_title') }}
      input( id="settings_meta_title" name="settings.meta_title" v-model="settings.meta_title" :class="{invalid: isInvalid('settings.meta_title')}")

    .v3-field
      label.label( for="settings_meta_keywords")
        | {{ translate('activerecord.attributes.product.meta_keywords') }}
      input( id="settings_meta_keywords" name="settings.meta_keywords" v-model="settings.meta_keywords" :class="{invalid: isInvalid('settings.meta_keywords')}")

    .v3-field
      label.label( for="settings_meta_description")
        | {{ translate('activerecord.attributes.product.meta_description') }}
      textarea( id="settings_meta_description" name="settings.meta_description" v-model="settings.meta_description" :class="{invalid: isInvalid('settings.meta_description')}")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true, default: () => ({meta_title: "", meta_keywords: "",meta_description: ""})}
  },
  emits:{
    close: {}
  },
}
</script>
