<template>
  <label class="v3-switch-wrap">
    <input type="checkbox" :checked="is_checked" @change="toggle"/>
    <span class="v3-switch" />
  </label>
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

let i = 0;
export default{
  inherit: false,
  props: {
    field: {},
    showTitles: {},
    className: {},
    object: {},
    onUpdate: {},
    valuesMap: {type: Object, default: ()=>({
        "active": "hidden",
        "hidden": "active",
        true: false,
        false: true,
      })
    },
  },

  data() {
    // needs to be unique in case multiple toggles are used on a page...
    return {
      input_name: `toggle-${(i+=1)}`,
      target: null,
      target_field: null
    };
  },

  computed: {
    value: {
      get() {
        return this.target[this.target_field] || "hidden";
      },
      set(v) {
        return this.target[this.target_field] = v;
      }
    },
    is_checked() {
      const val = this.value;
      return (val === 'active') || (val === 'true') || (val === true);
    }
  },

  watch: {
    object(value) {
      this.target = value;
    }
  },

  created() {
    if (!this.object) {
      let fields;
      if ((fields = this.field.split('.')).length > 1) {
        this.target = this.$parent[fields[0]];
        this.target_field = fields.slice(1).join('.');
      } else {
        this.target = this.$parent;
      }
    } else {
      this.target = this.object;
    }

    this.target_field || (this.target_field = this.field);
  },

  methods: {
    toggle(e) {

      const new_value = this.valuesMap[this.value];

      this.value = new_value;

      if (this.target.id) {
        if (this.onUpdate) { return this.onUpdate(e, this.target); } else { return this.$parent.onUpdate(e, this.target); }
      }
    }
  }
}
</script>