<template lang="pug">
.reveal-modal-container( :class="containerClass")
    div(class="reveal-modal-bg" @click="closeModal")
    div.reveal-modal( ref="reveal_modal" :class="modalClass" data-reveal="true")
      component(:is="component" v2-with="data" ref="component")
</template>
<script>
import base from '../../mixins/root_computed.js'
//modals
import products_seo from '../products/seo.vue'
import products_mass_price from '../products/mass/price.vue'
import products_mass_stock from '../products/mass/stock.vue'
import products_mass_delete from '../products/mass/delete.vue'
import orders_shipping_labels from "../orders/orders_shipping_labels.vue"
import media_library_delete_confirmation from '../media_library/delete_confirmation.vue'
import menu_form from '../menus/form.vue'
import menu_link from '../menus/link.vue'
import page_settings from '../pages/settings.vue'
import theme_delete_popup from '../themes/index/delete-popup.vue'
import settings_formats from '../settings/formats.vue'
import settings_id_format from '../settings/id-format.vue'
import settings_welcome_message from '../settings/welcome_message.vue'
import settings_contact from '../settings/contact.vue'
import settings_general from '../settings/general.vue'
import settings_password from '../settings/password.vue'
import settings_personal from '../settings/personal.vue'
import settings_seo from '../settings/seo.vue'
import domain_form from '../domains/form.vue'
import domain_instructions from '../domains/instructions.vue'
import domain_new_domain from '../domains/new-domain.vue'
import translations_language_new from '../translations/languages/new.vue'
import translations_language_edit from '../translations/languages/edit.vue'
import gift_cards_generate from "../gift_cards/generate.vue"
import gift_cards_form from "../gift_cards/form.vue"
import customer_subscription_form from "../customer_subscriptions/form.vue"
import procurements_cancel from "../inventory/procurements/cancel.vue"

export default{
  mixins: [base],
  components: {
    products_seo,products_mass_price,products_mass_stock,products_mass_delete,
    orders_shipping_labels,
    media_library_delete_confirmation,
    menu_form,menu_link,
    page_settings,
    theme_delete_popup,
    settings_formats,settings_id_format,settings_welcome_message,settings_contact,
    settings_general,settings_password,settings_personal,settings_seo,
    domain_form,domain_instructions,domain_new_domain,
    translations_language_new,translations_language_edit,
    gift_cards_generate,gift_cards_form,
    customer_subscription_form,
    procurements_cancel
  },
  data: ()=>({
    component: null,
    css_class: "",
    size: "large",
    modaltype: null,
    data: {},
  }),


  computed: {
    offset_height(){ return this.$refs.reveal_modal?.offsetHeight},
    inner_height(){  return window.innerHeight},
    full_modal(){    return this.inner_height < (this.offset_height || 0) ? "full-modal" : null},

    modalClass(){     return [this.size, this.full_modal]},
    containerClass(){ return [this.css_class, this.modaltype]},
  },

  mounted(){
    window.modal = this
    for (const key in this.$attrs){
      this[key] = this.$attrs[key]
    }
    this.$nextTick(_=>{
      if (this.size){
        document.querySelector('.reveal-modal').className = 'reveal-modal '+this.size
      }
      if (this.modaltype){
        const modal = document.querySelector('.reveal-modal-container')
        modal.className = modal.classList + this.modaltype
      }
      this.setModalSpacing()
      window.addEventListener('resize', (e)=>this.setModalSpacing(), true)

      const safeData = this.$attrs.data||{}
      for (const key in safeData){
        this.$refs.component[key] = safeData[key]
      }
      this.$refs.component.data_synced?.()
    })
  },

  methods: {

    setModalSpacing(){
      this.$nextTick(_=>{
        const modal = document.querySelector('.reveal-modal-container.admin-v2 .reveal-modal')
        if (modal?.querySelector('.modal-content')){
          modal.querySelector('.modal-content').style.marginTop = modal.querySelector('.modal-header').offsetHeight + 'px'
        }
      })
    },
  }
}
</script>
