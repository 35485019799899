import axios from 'axios'
import Qs from 'qs'

const baseURL = "/api/v1/"
export {
  axios,
  baseURL,
};

export const setupAxios= (vue)=>{
  const app = vue
  axios.defaults.baseURL = baseURL
  axios.defaults.paramsSerializer= (params)=>Qs.stringify(params, {arrayFormat: 'brackets'})
  axios.interceptors.request.use(function (config) {
    config.headers["Accept-Version"]  = "v1"
    config.headers["Accept-Language"] = I18n.locale
    config.headers["Current-Shop"]    = window.localStorage.shop_domain

    const request_id   = app.request_sequence
    const requests_now = app.current_requets || []
    requests_now.push(request_id)
    app.current_requets  = requests_now
    app.request_sequence = request_id + 1
    config.request_id = request_id

    return config;
  }, function (error) {
    return Promise.reject(error);
  });
  axios.interceptors.response.use(function (response) {
    const response_languge       = response.headers["X-Content-Language"]  || response.headers["x-content-language"]
    const response_languges      = response.headers["X-Content-Languages"] || response.headers["x-content-languages"]
    const response_asset_version = response.headers["X-Assets-Version"]    || response.headers["x-assets-version"]
    const pagination             = response.headers["X-Pagination"]        || response.headers["x-pagination"]
    if (response_languge)  app.locale = response_languge
    if (response_languges) app.possible_admin_locales = response_languges.split(",")
    if (pagination && response.data instanceof Array) response.data.pagination = JSON.parse(pagination)

    if (response_asset_version){
      if (app.old_assets_version && app.old_assets_version !== response_asset_version) app.got_new_assets = true
      app.old_assets_version = response_asset_version
    }

    if (app.current_requets) app.current_requets.splice(app.current_requets.indexOf(response.config.request_id), 1)

    const method = response.config.method
    if (method === "post" || method === "patch" || method === "put" || method === "delete") {
      app.flash = null
      const url = response.config.url.toString()
      if (!url.includes("login") && !url.includes("skip_flash=true") && !url.includes("search")) {
        app.$nextTick(() => app.flash = "admin.flash.success")
      }
    }

    return response;
  },function (error) {
    if (app.current_requets && error.response) app.current_requets.splice(app.current_requets.indexOf(error.response.config.request_id), 1)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  })
}