import { defineStore } from 'pinia'
import {baseURL, axios} from "@/admin/application/axios";

const username = (user) => {
  if (!user) { return ""; }
  return [user.first_name, user.last_name].join(" ").trim() || user.email || "";
}
const loadAvatar = (user) => {
  return axios.get(`/images`, {params: {where: `entity_type:User,entity_id:${user.id}`}}).then(r => r.data[0])
}

const loadAcl = () =>
  axios.get(`/user/acl`,).then(r=>r.data)

export {
  username,
  loadAvatar,
}


export const useUser = defineStore('user', {
  state: () => ({
    user: {},
    avatar: null,
    acl: null,
    aclPromise: null,
  }),
  getters: {
    username(){ return username(this.user) },
  },
  actions: {
    loadAvatar(){
      if (!this.user.id) return
      return loadAvatar(this.user).then(json=>this.avatar=json)
    },
    loadAcl(reload = false){
      if (!this.aclPromise || reload)
        return this.aclPromise = loadAcl().then(json => this.acl = json).catch(()=> this.aclPromise=null)
      else return this.aclPromise
    },
    can(...vars_with__){
      for (const str of vars_with__){
        if (this.canOne(str)) return true
      }
      return false
    },
    canOne(var_with__){
      if (!this.acl) return false
      const [domain, ...rights] = var_with__.split("__")
      const domainAcl = this.acl[domain]
      //console.log(domain,rights,domainAcl)
      if (domainAcl === true) {
        return true
      } else if (domainAcl === undefined) {
        return false
      } else {
        if (rights.length===0){
          return Object.values(domainAcl).some(right=>right)
        }else {
          return rights.some(right => domainAcl[right])
        }
      }
    },
  }
})