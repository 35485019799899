<template lang="pug">
div(id="v3-loader" v-if="!ready")
shoperb-modal(:label="translate('admin.product.edit.change_price' )" size="lg" @close="closeModal")
  template(#default)
    value_price(:variant="variant")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="closeModal")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>
import Root from '../../../mixins/root_computed.js';
import value_price from '../../variants/attribute/value-price.vue';
export default{
  components: {value_price},
  mixins: [Root],
  props:{
    onDone: {type: Function, required: true},
    variant: {type: Object, required: true},
  },
  emits:{
    close: {},
  },
  data() {
    return {
      ready: false
    };
  },

  methods: {
    data_synced() { this.ready = true; },

    onSubmit(e) {
      e?.preventDefault();
      this.onDone?.(this.variant.price, () => this.closeModal());
    },

    closeModal(){
      this.$emit('close')
    }
  }
}
</script>