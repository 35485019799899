<template lang="pug">
div(id="v3-loader" v-if="!ready")
shoperb-modal(:label="translate('admin.products.seo.seo_settings' )" size="lg" @close="onCancel")
  template(#default)
    .v3-field
      helpers-label.label(field="product.meta_title")
      input( name="product.name" v-model="product.meta_title" :class="{invalid: isInvalid('product.meta_title')}")
      helpers-validation-message.error(:errors="validation" field="product.meta_title")

    .v3-field
      helpers-label.label(field="product.meta_description")
      textarea( name="product.meta_description" v-model="product.meta_description" :class="{invalid: isInvalid('product.meta_description')}")
      helpers-validation-message.error(:errors="validation" field="product.meta_description")

    .v3-field
      .general-handle
        .field.same-row-elements
          .input
            .fake-placeholder( style="white-space: nowrap;" @click="focusPermalink")
              | https://{{current_shop.external_hostname}}/products/
            input( name="product.permalink" v-model="product.permalink" :class="{invalid: isInvalid('product.permalink')}" style="display: inline-block;" ref="handle_permalink")
            .fake-input
            helpers-validation-message.error(:errors="validation" field="product.permalink")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="onCancel")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")

</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
export default{
  mixins: [Root],
  emits:{
    close: {}
  },
  props:{
    static: {type: Object, required: true},
    product: {type: Object, required: true},
  },
  data() {
    return {
      ready: false
    };
  },

  validations: {
    "product.permalink": "required, maxLength: 255"
  },

  methods: {
    focusPermalink() {
      return this.$refs.handle_permalink.focus();
    },

    data_synced() {
      return this.ready = true;
    },

    onCancel() { return this.$emit('close') },

    updateParent() {
      this.static.meta_title = this.product.meta_title
      this.static.permalink = this.product.permalink
      this.static.meta_description = this.product.meta_description
    },

    onSubmit(e) {
      e?.preventDefault();
      if (this.static.id) {
        return this.axios.patch( `/products/${this.static.id}`, {product: this.product}).then(response => {
          this.updateParent();
          return this.onCancel();
        }
        , response => this.set_validation2(this, "product", response));
      } else {
        this.updateParent();
        return this.onCancel();
      }
    }
  }

}
</script>
