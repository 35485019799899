<template lang="pug">
form( @submit="onSubmit" @keydown.esc="onCancel")
    .modal-header

      h2( v-if="page.handle")
        | {{translate('admin.pages.new.edit')}}
      h2( v-if="!page.handle")
        | {{translate('admin.pages.new.title')}}
      .icon-delete( @click="closeModal")

    .modal-content
      .inner-container
        .field.clearfix
          label.left
            | {{ translate('admin.integration.form.status') }}
          .toggle-container.right
            toggle-v2(field="state" :object="page" :onUpdate='toggleStatus')

      .field
        helpers-label.label(field="page.name")
        input( name="page.name" v-model="page.name" :class="{invalid: isInvalid('page.name')}")
        helpers-validation-message.error(:errors="validation" field="page.name")

      .field( v-if='page.id')
        helpers-label.label(field="page.permalink")
        input( v-model="page.permalink" :class="{invalid: isInvalid('page.permalink')}")
        helpers-validation-message.error(:errors="validation" field="page.permalink")

      .field
        helpers-label.label(field="page.handle")
        input( name="page.handle" v-model="page.handle" :class="{invalid: isInvalid('page.handle')}" @keyup="stopGenHandle")
        helpers-validation-message.error(:errors="validation" field="page.handle")

      .field
        helpers-label.label(field="page.template")
        select-autocomplete(v-model="page.template_id" :collection="mapped_templates" @change="page.template = $event.id")

      .field
        helpers-label.label(field="page.meta_keywords")
        input( name="page.meta_keywords" v-model="page.meta_keywords" :class="{invalid: isInvalid('page.meta_keywords')}")
        helpers-validation-message.error(:errors="validation" field="page.meta_keywords")

      .field
        helpers-label.label(field="page.meta_description")
        input( name="page.handle" v-model="page.meta_description" :class="{invalid: isInvalid('page.meta_description')}")
        helpers-validation-message.error(:errors="validation" field="page.meta_description")

    .modal-footer( v-if="page.handle")
      .left
        .button.delete.inverted.short(v-if="deletable_page" @click="onDestroy")
          | {{translate('admin.buttons.delete')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.buttons.save')}}
    .modal-footer( v-if="!page.handle")
      .left
        .button.secondary.inverted.short( @click="closeModal")
          | {{translate('admin.buttons.cancel')}}
      .right
        button.button.primary.short( type="submit" @click="onSubmit")
          | {{translate('admin.pages.new.add_page')}}
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import gen_handle from '../../mixins/gen_handle.js';
export default{
  mixins: [Root,gen_handle],
  model_klass: "page",

  data() { return {
    page: {state: 'hidden', name: '', handle: '', translations: {}},
    pages_templates: [],
  }; },
  computed: {
    deletable_page() {
      return this.page.permalink !== 'terms-and-conditions'
    },
    mapped_templates() {
      const arr = this.pages_templates.map(item => ({
        id: item[1],
        name: item[0]
      }))
      arr.unshift({id:null, name: this.translate("admin.search.filter.all")});
      return arr
    }
  },
  created() {
    this.fetch_cached_collection("pages/templates")
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.$validate()) { return; }

      if (this.page.id) {
        return this.onUpdate(e);
      } else {
        return this.onCreate(e);
      }
    },

    onUpdate(e) {
      this.axios.patch(
        `/pages/${this.page.id}`, {page: this.page}
      ).then((response) => {
        this.closeModal()
        return this.validation.page = {};
      }).catch(response => {
        this.set_validation2(this, "page", response);
        return Cache.deleteBy('pages');
      })
    },

    onCreate(e) {

      this.axios.post(
        "pages", {page: this.page}
      ).then((obj) => {
        this.closeModal()
        return this.refresh(`/pages/${obj.data.permalink}/edit`);
      }).catch(response => {
        this.set_validation2(this, "page", response)
      })
    },

    onCancel(e) {
      return this.closeModal();
    },

    onDestroy(e) {
      e.preventDefault();
      return this.axios.delete(`/pages/${this.page.id}`).then(response => {
        this.closeModal();
        return this.refresh("/pages");
      });
    },

// template data {text: e[0], value: e[1]}
    templateName(val){
      for (var e of (this.pages_templates || [])) {
        if (e[1] === val) { return e[0]; }
      }
      return I18n.translate('admin.product.edit.template_placeholder');
    },

    toggleStatus(e) {
      if (!this.page.id) { return; }
      return this.axios.patch(`pages/${this.page.id}`, {page: {state: this.page.state}});
    }
  }
}
</script>
