<template lang="pug">
div
  shoperb-modal(:label="language.name" size="lg" @close="onCancel")
    template(#default)
      .v3-field.clearfix
        .left
          | {{translate('admin.translations.edit.publish')}}
        .right
          .toggle-container
            toggle-v2(field="published_toggle", :object="that")

    template(#footer)
      shoperb-buttons(class="w-full")
        template(#secondary)
          shoperb-button(role="destroy" :label="translate('admin.buttons.delete')" outline @click="onDelete" v-if="$can('translations__delete')")
        shoperb-button(role="primary" :label="translate('admin.translations.edit.update_language')" @click="onUpdate" v-if="$can('translations__update')")
</template>
<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '../../../mixins/root_computed.js';
import mixin_language from './language.js';
export default{
  mixins: [Root,mixin_language],
  props:{
    language: {type: Object, required: true},
    languages: {type: Array, required: true},
  },
  data() {
    return {
      loaded: true
    };
  },

  methods: {
    data_synced() { return this.loaded = true; },

    onCancel() { return this.closeModal(); },

    onDelete(e) {
      e.preventDefault();
      this.active = false;
      return this.performAction();
    },

    onUpdate(e) {
      e.preventDefault();
      return this.performAction();
    }
  }
}
</script>