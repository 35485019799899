<template>
  <div class="flex flex-col w-full" v-if="['text','password','email', 'number'].includes(type)">
    <label :for="id" v-if="label" class="flex font-medium mb-1 text-sm">
      {{ label }}
    </label>
    <input v-model="value" v-bind="$attrs" :type=type :id="id" :name="name" :placeholder="placeholder" :tabindex="tabindex" :disabled="disabled" :class="[cssClass, 'px-2 block w-full rounded border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6']"  />
  </div>

  <div class="flex flex-col w-full" v-if="['search'].includes(type)">
    <label :for="id" v-if="label" class="block text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </label>
    <div class="relative flex items-center">
      <input v-model="value" :type=type :id="id" :placeholder="placeholder" :tabindex="tabindex" :class="[cssClass, 'reset-input block w-full rounded border-0 py-1.5 pl-8 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6']">
      <div class="absolute inset-y-0 left-0 flex py-1.5 pl-1.5 mt-0.5">
        <shoperb-icon name="search" size="w-5 h-5" stroke-width="stroke-3" color="text-gray-400" />
      </div>
    </div>
  </div>

  <div class="flex flex-col w-full" v-if="['adjustment'].includes(type)">
    <label :for="id" v-if="label" class="flex text-sm font-medium leading-6 text-gray-900">
      {{ label }}
    </label>

    <div
      @click="focusInput"
      :class="[
      'flex flex-row items-center justify-end px-2 py-0.5 rounded text-gray-900 shadow ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 max-w-fit sm:text-sm sm:leading-2 cursor-pointer',
      'hover:ring-2 hover:ring-inset hover:ring-blue-600',
      isInputFocused ? 'ring-2 ring-inset ring-blue-600' : ''
    ]"
    >
      <input ref="inputRef"
             v-model="value"
             :style="{ width: inputWidth }"
             type="number"
             class="reset-input text-right inline-flex border-0 bg-transparent p-0 focus:ring-0 text-sm mr-1 py-1.5 max-w-18"
             @focus="isInputFocused = true"
             @blur="isInputFocused = false"
             v-bind="$attrs"
      >
      <div class="whitespace-nowrap">out of {{outOf}}</div>
    </div>
  </div>

  <div v-if="false">
    <label for="email" class="block text-sm font-medium leading-6 text-gray-900">Email</label>
    <div class="mt-2">
      <input type="email" name="email" id="email" class="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" placeholder="you@example.com">
    </div>
  </div>


  <div class="flex flex-col" v-else-if="['currency'].includes(type)">
    <div>
      <label :for="id" v-if="label" class="flex font-medium mb-1 text-sm">
        {{ label }}
      </label>

      <div class="relative rounded-md shadow-sm">
        <div class="flex flex-row">
          <input v-model="value" type="number" :name="name" :id="id" :placeholder="placeholder" :disabled="disabled" :step="step" :class="[cssClass, 'text-right flex bg-white w-full rounded border-0 py-1.5 pr-14 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6', disabled ? 'bg-gray-500' : 'bg-white focus:ring-2 focus:ring-blue-600']" />
        </div>

        <div class="absolute inset-y-0 right-0 flex items-center m-1 bg-gray-200 rounded-sm pointer-events-none">
          <span class="flex px-2 font-medium text-sm">{{ unit }}</span>

        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';

const inputRef = ref(null);
const isInputFocused = ref(false);
const inputValue = ref('');

const minWidth = 4; // Minimum width in characters
const charWidth = 12; // Approximate width of a character in pixels

const inputWidth = computed(() => {
  const contentWidth = Math.max(inputValue.value.length, minWidth) * charWidth;
  return `${contentWidth}px`;
});

const focusInput = () => {
  inputRef.value.focus();
};

const updateInputValue = (event) => {
  inputValue.value = event.target.value;
};
</script>

<script>
const minWidth = 4; // Minimum width in characters
const charWidth = 8; // Approximate width of a character in pixels

export default {
  name: "shoperb-form-input",
  components: {  },
  props: {
    modelValue: {type: [String, Number], default: ''},
    label: {
      type: String
    },
    type: {
      type: String,
      required: true,
      default: "text"
    },
    id: {
      type: String,
    },
    placeholder: {
      type: String
    },
    unit: {
      type: String
    },
    step: {
      type: String
    },
    tabindex: {
      type: Number
    },
    name: {
      type: String,
      required: false,
    },
    required: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    outOf: {
      type: Number,
      required: false,
    }
  },
  emits: {
    'update:modelValue': {}
  },
  data() {
    return {
      isFocused: false,
    };
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    cssClass(){
      switch(this.type){
        case 'search':
          return 'number'
        default:
          return this.type
      }
    },
    inputWidth(){
      const contentWidth = Math.max(this.value.toString().length, minWidth) * charWidth;
      return `${contentWidth}px`;
    }
  },
  methods: {
    handleFocus() {
      this.isFocused = true;
    },
    handleBlur() {
      this.isFocused = false;
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./app/javascript/admin/styles/admin-v3/variables.scss";

input {
  //height: $form-element-height;
  //background-color: $form-element-background-color;
  //border: 1px solid $form-element-border-color; // HSL: 215, 25, 75
  //box-shadow: inset 0 0 3px 0 #A3B0C2, inset 0 0 5px 0 #D1D7E0;
  //box-shadow: inset 0 0 3px 0 rgba(163,176,194,0.6), inset 0 0 6px 0 rgba(209,215,224,0.3);
  //box-shadow: inset 0 0 3px 0 rgba(163, 176, 194, 0.3), inset 0 0 6px 0 rgba(209, 215, 224, 0.15);
  //border-radius: $form-element-border-radius;
  //padding: 0 10px;
  //font-size: 15px;

  &:hover {
    //background: #FCFCFD;
    //background: #FFFFFF;
    //border-color: #8BA1C1;
    //background: #FCFCFD;
    background: $form-element-background-color-hover;
    //border-color: #2671D9;
    border-color: $form-element-border-color-hover;
    //box-shadow: inset 0 0 3px 0 #8BA1C1, inset 0 0 6px 0 #BDC9DB;
  }

  &:focus {
    //background: #FCFCFD;
    //background: #FFFFFF;
    //border-color: #2671D9;
    //box-shadow: inset 0 0 3px 0 #679BE4, inset 0 0 6px 0 #A8C6F0;
    //box-shadow: 0 0 0 4px #E8F1FC;
    //outline: none;
  }

  &[disabled] {
    background-color: #F2F2F2;
    border-color: #D9D9D9;
    box-shadow: none;
  }

  &.success {
    background: #FCFCFD;
    background: #FFFFFF;
    border: 2px solid #29A352;
    box-shadow: inset 0 0 3px 0 #82C99A, inset 0 0 6px 0 #B8E0C5;
  }

  &.error {
    background: #FCFCFD;
    background: #FFFFFF;
    border: 2px solid #D92626;
    box-shadow: inset 0 0 3px 0 #C98282, inset 0 0 6px 0 #E0B8B8;
  }
}

.v3-field {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
    color: #29313D;
    font-size: 0.875rem;
    padding: 0 5px;
    line-height: 1;
    margin-bottom: 5px;
    white-space: nowrap;
    cursor: pointer;
  }

  .error {
    font-size: 12px;
    color: #FF4D4F;
  }
}
</style>