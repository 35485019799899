import Base from './base.js';
const root_computed = function(key) {
  return {
    get() { return this.$root[key]; },
    set(value) { return this.$root[key] = value; }
  };
};

export default {
  mixins: [Base],
  computed: {
    request_sequence: root_computed("request_sequence"),
    current_requests: root_computed("current_requests"),
    current_shop: root_computed("current_shop"),
    data_language: root_computed("data_language"),
    current_user: root_computed("current_user"),
    possible_admin_locales: root_computed("possible_admin_locales"),
    keymaster: root_computed("keymaster"),
    shops: root_computed("shops"),
    flash: root_computed("flash"),
    modal_delete: root_computed("modal_delete"),
    currencies: root_computed("currencies"),
    move_to: root_computed('move_to'),
    got_new_assets: root_computed('got_new_assets'),
    old_assets_version: root_computed('old_assets_version'),
    page: root_computed('page'),
    isRouterAlive: root_computed('isRouterAlive'),
    parent_validation: {
      get() { return this.$parent["validation"]; },
      set(value) { return this.$parent["validation"] = value; }

    },
  },
  methods:{
    reload() {
      this.isRouterAlive = false;
      window.setTimeout((() => { return this.isRouterAlive = true; }), 0);
    }
  }
}