<template lang="pug">
div(id="v3-loader" v-if="!countries_ready")
shoperb-modal(:label="translate('admin.settings.edit.edit_contact_information' )" size="lg" @close="onCancel($event)")
  template(#default)
    div
      .invalid.warning( v-if="!valid")
        | {{ translate('admin.settings.address.invalid' ) }}

      .v3-field
        label.label
          | {{ translate('admin.settings.address.account_type' ) }}
        select.fancy-select-2( v-model="settings.address.account_type")
          option( value="personal")
            | {{ translate('admin.settings.address.account_types.personal' ) }}
          option( value="company")
            | {{ translate('admin.settings.address.account_types.business' ) }}
        helpers-validation-message.error(:errors="validation" field="settings.address.account_type")

      .v3-field( v-if="settings.address.account_type === 'company'")
        helpers-label.label(field="settings.address.company")
        input( name="settings.company" v-model="settings.address.company" :class="{invalid: isInvalid('settings.address.company')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.company")

      .v3-field( v-if="settings.address.account_type === 'company'")
        helpers-label.label(field="settings.address.registration_number")
        input( v-model="settings.address.registration_number" :class="{invalid: isInvalid('settings.address.registration_number')}" :placeholder="translate('activerecord.attributes.settings.registration_number_placeholder')")
        helpers-validation-message.error(:errors="validation" field="settings.address.registration_number")

      .v3-field( v-if="settings.address.account_type === 'company'")
        helpers-label.label(field="settings.address.vat_number")
        input( v-model="settings.address.vat_number" :class="{invalid: isInvalid('settings.address.vat_number')}" :placeholder="translate('activerecord.attributes.settings.vat_number_placeholder')")
        helpers-validation-message.error(:errors="validation" field="settings.address.vat_number")

      .v3-field
        helpers-label.label(field="settings.address.address1")
        input( name="settings.address1" v-model="settings.address.address1" :class="{invalid: isInvalid('settings.address.address1')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.address1")
        .additional-address( @click="additional_address=!additional_address" v-if="!additional_address")
          | {{ translate('admin.shared.add_address_line' ) }}

      .v3-field( v-if="additional_address")
        helpers-label.label(field="settings.address.address2")
        input( name="settings.address2" v-model="settings.address.address2" :class="{invalid: isInvalid('settings.address.address2')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.address2")

      .v3-field
        helpers-label.label(field="settings.address.zip")
        input( name="settings.zip" v-model="settings.address.zip" :class="{invalid: isInvalid('settings.address.zip')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.zip")

      .v3-field.clearfix
        helpers-label.label(field="settings.address.country_id")
        select(v-model="settings.address.country_id")
          option(v-for="country in  country_options()" :value="country.value") {{country.text}}
        helpers-validation-message.error(:errors="validation" field="settings.address.country_id")

      .v3-field.clearfix
        helpers-label(field="settings.address.state_id")
        select(v-model="settings.address.state_id")
          option(v-for="option in state_options()" :value="option.value") {{option.text}}
        helpers-validation-message.error(:errors="validation" field="settings.address.state_id")

      .v3-field
        helpers-label.label(field="settings.address.city")
        input( name="settings.city" v-model="settings.address.city" :class="{invalid: isInvalid('settings.address.city')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.city")

      .v3-field
        helpers-label.label(field="settings.address.phone")
        input( name="settings.phone" v-model="settings.address.phone" :class="{invalid: isInvalid('settings.address.phone')}")
        helpers-validation-message.error(:errors="validation" field="settings.address.phone")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="$emit('close')")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true}
  },
  emits:{
    close: {}
  },
  data() {
    return {
      additional_address: null,
      countries: [],
      countries_ready: null,
      current_country: {},
      render_states: null,
      all_states: {},
      valid: true
    };
  },

  validations: {
    "settings.address.city":       "required, maxLength: 255",
    "settings.address.company":             "function: val_company",
    "settings.address.registration_number": "function: val_company",
    "settings.address.phone":      "required, maxLength: 255",
    "settings.address.address1":   "required, maxLength: 255",
    "settings.address.zip":        "required, maxLength: 255",
    "settings.address.country_id": "required"
  },

  created() {
    return this.if_authenticated().then(() => {
      return this.fetch_cached_collection('countries').then(() => {
        this.countries_ready = true;
        if (this.settings.address.account_type == null) { this.settings.address.account_type = "company"; }
        this.$watch("settings.address.country_id", this.clearState);
        return this.$watch("settings.address.country_id", this.fetchStates, {immediate: true});
      });
    });
  },

  methods: {
    clearState() { if (this.settings.address) { return this.settings.address.state_id = null; } },

    fetchStates() {
      const id = parseInt(this.settings.address.country_id);
      this.current_country = this.filter(this.countries, country => country.id === id)[0];
      this.render_states = false;

      if (this.current_country && this.current_country.has_states) {
        if (this.all_states[id] === undefined) {
          return this.axios.get("states", {where: `country_id:${id}`}).then(states => {
            this.all_states[id] = states.data
            this.set_default_state(id);
            return this.render_states = true;
          });
        } else {
          this.set_default_state(id);
          return this.render_states = true;
        }
      }
    },

    set_default_state(country_id) {
      const states = this.all_states[country_id];
      const current = parseInt(this.settings.address?.state_id);
      if (!this.filter(states, state => state.id === current)[0]) {
        return this.settings.address.state_id = this.all_states[country_id][0]?.id;
      }
    },

    state_options() {
      return (this.all_states[this.current_country?.id] || []).map(state => ({
        text: state.name,
        value: state.id
      }));
    },

    country_options() {
      return (this.countries || []).map(country => ({
        text: country.name,
        value: country.id
      }));
    },

    val_company() {
      if (this.settings.address.account_type === "company") {
        return this.$sOptions.validators["required"].apply(this, arguments);
      } else { return true; }
    }
  }
}
</script>
