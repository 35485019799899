<template lang="pug">
shoperb-modal(:label="translate('admin.settings.edit.edit_store_information')" size="lg" @close="$emit('close')")
  template(#default)
    .v3-field
      helpers-label(field="settings.name")
      input(name="settings.name" v-model="settings.name" :class="{invalid: isInvalid('settings.name')}")
      helpers-validation-message.error(:errors="validation" field="settings.name")

    .v3-field
      helpers-label.label(field="settings.email")
      input( name="settings.email" v-model="settings.email" :class="{invalid: isInvalid('settings.email')}")
      helpers-validation-message.error(:errors="validation" field="settings.email")

  template(#footer)
    shoperb-buttons(class="w-full")
      template(#secondary)
        shoperb-button(role="tertiary" :label="translate('admin.buttons.cancel')" outline @click="$emit('close')")
      shoperb-button(role="primary" :label="translate('admin.buttons.save')" @click="onSubmit")
</template>

<script>
import Root from '@/admin/mixins/root_computed.js';
import settings from './settings.js';
export default{
  mixins: [Root,settings],
  props:{
    settings: {type: Object, required: true}
  },
  emits:{
    close: {}
  },
  validations: {
    "settings.name":               "required, maxLength: 255",
    "settings.email":              "required, email, maxLength: 255"
  }
}
</script>
