/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */

import Sugar from 'sugar/object'
export default {
  created() {
    return this.if_authenticated().then(() => {
      return this.old_settings = JSON.parse(JSON.stringify(this.settings));
    });
  },

  methods: {
    onCancel(e) {
      e?.preventDefault();
      for (var key in this.settings) { this.settings[key] = this.old_settings[key]; }
      this.closeModal();
    },

    onSubmit(e) {
      e.preventDefault();
      this.clean_all_validations(this);
      if (!this.$validate()) { return; }
      const request = this.axios.patch("/settings", {settings: this.settings});
      request.then(response => {
        Sugar.Object.merge(this.current_shop, response.data, {deep: true});
        Sugar.Object.merge(this.settings, response.data, {deep: true});
        return this.closeModal();
      });
      return request.catch(response => {
        const address = response.response.data?.address;
        if (address.errors && !address.errors?.data.first_name && !address.errors?.data.last_name) {
          this.closeModal();
          this.modal = {
            component: "settings_contact",
            css_class: "admin-v2",
            data: {settings: this.settings, valid: false}
          };
        }
        return this.set_validation2(this, 'settings', response);
      });
    },

    closeModal(){
      this.$emit('close')
    }
  }
}